import { Typography } from '@material-ui/core';
import React from 'react';

const Dashboard = () => (
  <div>
    <Typography variant="h5" component="h2">
      Hello zu deiner eigenen Maklerseite
    </Typography>
  </div>
);

export default Dashboard;
